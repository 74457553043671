<template>
  <v-card tile color="transparent" class="pa-0 dashboard" elevation="0">
    <v-app-bar fixed app class="toolbar justify-end" :color="systemDetails.themecolor">
      <v-select class="mt-6 widget-select" dense outlined height="33" :items="widgetView" item-text="label" item-value="value" v-model="currentWidgetView"></v-select>
    </v-app-bar>
    <v-card tile color="transparent" class="pa-0" elevation="0">
      <v-container fluid>
        <v-row v-if="showDashboard">
          <v-col cols="12" v-for="widgetItem in 3" :key="widgetItem">
            <v-skeleton-loader ref="skeleton" type="card-avatar" :tile="false" class="mx-auto"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else-if="listOfTotalWidgets.selectedcharts.length">
          <v-container class="pa-0 pa-2" fluid>
            <v-expansion-panels multiple :value="[0, 1]" flat>
            <!-- Widget section to show other user created widgets -->
              <v-expansion-panel v-show="(currentWidgetView === 0 || currentWidgetView === 2)" :disabled="!otherWidgetsCount" class="rounded-xl">
                <v-expansion-panel-header class="px-5 py-3 rounded-xl" style="border: 1px solid grey !important; min-height: 40px" color="transparent">
                  <span class="pr-5 h4 font-weight-bold">{{ $t('otherUsersWidgets') }}
                    <v-avatar class="mb-1 ml-1" size="19" :color="`${systemDetails.themecolor}`">
                      <span :style="`color: ${systemDetails.textcolor}; font-size: 12px`">{{ otherWidgetsCount }}</span>
                    </v-avatar>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="panel-content mt-2">
                  <template>
                    <v-row>
                      <template v-for="(widget, index) in listOfTotalWidgets.selectedcharts">
                        <v-col cols="12" lg="6" sm="12" md="12" :key="widget.sortid + reInit" class="pb-0 px-5 mb-2" v-if="widget.canShow && !widget.isCreatedByMe">
                          <widget
                          :widget="widget"
                          :pagination="pagination"
                          :listOfWidgets="listOfTickets"
                          :widgetCount="ticketCount"
                          :chartOptionsTickets="widget.ticketChartOption"
                          :index="index"
                          :createdBy="'others'"
                          :listOfRecentDocuments="listOfRecentDocuments"
                          :listOfWorkflows="listOfWorkflows"
                          :listOfRecentActivities="listOfRecentActivities"
                          :chartOptions="chartOptions"
                          :widgetDetails="widget.widgetDetails"
                          :listOfQuickLinks="listOfQuickLinks"
                          :hasTimerPermission="hasTimerPermissionOnly"
                          :hoursChartOptions="hoursChartOptions"
                          :hasTicketPermission="hasTicketPermission"
                          :ticketChartOptions="ticketInfoChartOptions">
                          </widget>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            <!-- Widget section to show widgets only created by me -->
              <v-expansion-panel v-show="currentWidgetView === 0 || currentWidgetView === 1" :disabled="!myWidgetsCount" class="rounded-xl mt-2">
                <v-expansion-panel-header class="px-5 py-3 rounded-xl" style="border: 1px solid grey !important; min-height: 40px">
                  <span class="pr-5 h4 font-weight-bold">{{ $t('widgetCreatedByMe') }}
                    <v-avatar class="mb-1 ml-1" size="19" :color="`${systemDetails.themecolor}`">
                      <span :style="`color: ${systemDetails.textcolor}; font-size: 12px`">{{ myWidgetsCount }}</span>
                    </v-avatar>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="panel-content mt-2">
                  <template v-for="(widget, index) in listOfTotalWidgets.selectedcharts">
                    <v-col cols="12" lg="6" sm="12" md="12" :key="widget.sortid + reInit" class="pa-0 px-2 mb-5" v-if="widget.canShow && widget.isCreatedByMe">
                      <widget
                        :widget="widget"
                        :pagination="pagination"
                        :listOfWidgets="listOfTickets"
                        :widgetCount="ticketCount"
                        :chartOptionsTickets="widget.ticketChartOption"
                        :index="index"
                        :createdBy="'me'"
                        :listOfRecentDocuments="listOfRecentDocuments"
                        :listOfWorkflows="listOfWorkflows"
                        :listOfRecentActivities="listOfRecentActivities"
                        :chartOptions="chartOptions"
                        :widgetDetails="widget.widgetDetails"
                        :listOfQuickLinks="listOfQuickLinks"
                        :hasTimerPermission="hasTimerPermissionOnly"
                        :hoursChartOptions="hoursChartOptions"
                        :hasTicketPermission="hasTicketPermission"
                        :ticketChartOptions="ticketInfoChartOptions"
                        @edit="editUserDefinedWidget(widget, index)"
                        @remove="removeWidgetConfirmation(index, widget.id)">
                      </widget>
                    </v-col>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-row>
        <template v-else>
          <v-alert icon="mdi-information-outline" prominent text type="info" class="mt-4">
            {{ $t('widgetNotFound') }} <strong>{{ $t('widgetInfo') }}</strong>
          </v-alert>
        </template>
      </v-container>
    </v-card>
    <!-- speed dial -->
    <v-speed-dial v-model="toggleSpeedDial" fixed bottom right class="widget-custom-speed-dial" v-if="!showDashboard">
      <template #activator>
        <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }" fab dark class="mt-2">
          <v-icon v-if="!toggleSpeedDial"> mdi-plus </v-icon>
          <v-icon v-else> mdi-close </v-icon>
        </v-btn>
      </template>
      <template v-for="(item, index) in widgetTemplates">
        <v-btn class="text-capitalize my-0 mb-1" small min-width="80px" :style="{ color: systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
        v-if="!item.disabled" :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'"
        :key="index" @click="addNewWidget(item.value)">
          {{ item.title }}
        </v-btn>
      </template>
    </v-speed-dial>
    <!--User defined widget dialog-->
    <v-dialog fullscreen v-model="userDefineDialog" width="1000" persistent transition="scroll-y-reverse-transition">
      <!-- <div style="height: 600px"> -->
        <v-card height="100%">
          <v-toolbar class="widget-toolbar-custom-height" dark :color="systemDetails.themecolor">
            <v-toolbar-title :style="{ color: systemDetails.textcolor }">{{$t('widgetConfiguration')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon :color="systemDetails.textcolor" @click="closeUserDefinedDialog">mdi-close-circle</v-icon>
            <v-icon class="mb-2" @click="closeUserDefinedDialog"></v-icon>
          </v-toolbar>
          <v-form ref="userdefineDialog">
            <v-card-text style="height: calc(var(--vh, 1vh)*100 - 90px)" class="overflow-y-auto">
              <v-row class="px-3 mt-0">
                <v-col cols="12" class="py-2 px-0">
                  <v-text-field outlined dense hide-details :label="$t('name')" v-model="userdefinedObj.name" :rules="$_requiredValidation"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-2 px-0">
                  <v-autocomplete :items="listOfActiveModules" :item-text="(val) => $t(val.plural_label)" item-value="_id" outlined dense hide-details :label="$t('module')" @change="getListOfFieldsData(); userdefinedObj.conditions = [{}]; activeFilterPanel=[0]"
                    v-model="userdefinedObj.module" :rules="$_requiredValidation"></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-2 px-0 align-center d-flex"> {{ $t('widgetType') }} :
                  <v-btn-toggle v-model="userdefinedObj.dataformat" mandatory group tile color="purple" class="ml-10">
                    <v-btn height="36" min-width="36" width="36" :value="1">
                      <v-icon>mdi-chart-bar</v-icon>
                    </v-btn>
                    <v-btn height="36" min-width="36" width="36" :value="2">
                      <v-icon>mdi-chart-pie</v-icon>
                    </v-btn>
                    <v-btn height="36" min-width="36" width="36" :value="3">
                      <v-icon>mdi-table-large-plus</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" v-if="[1, 2].includes(userdefinedObj.dataformat)" class="py-2 px-0">
                  <v-autocomplete :disabled="!listOfFields.length" :items="listOfGroupbyFields" :item-text="val => $t(val.label)" item-value="_id" outlined dense hide-details :label="$t('groupBy')"
                    v-model="userdefinedObj.groupby" :loading="moduleFieldsLoading" :rules="$_requiredValidation"></v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="userdefinedObj.dataformat === 3" class="py-2 px-0">
                  <v-autocomplete class="visible-fields" :disabled="!listOfVisibleFields.length" multiple small-chips :items="listOfVisibleFields" :item-text="val => $t(val.label)" item-value="_id" outlined dense hide-details :label="$t('visibleFields')"
                    v-model="userdefinedObj.visiblefields" :loading="moduleFieldsLoading" :rules="$_requiredValidation" @change="checkNameField">
                    <template v-slot:no-data v-if="!userdefinedObj.visiblefields">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('startTyping') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="data">
                      <draggable :id="data.index" :list="userdefinedObj.visiblefields" v-bind="dragOptionsChips" :move="move" @change="change">
                        <v-chip style="margin: 2px 4px" small draggable v-model="userdefinedObj.visiblefields[data.index]" :key="data.item.value" @mousedown.stop @click.stop>
                          {{ data.item.label }}
                        </v-chip>
                      </draggable>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="[1, 2].includes(userdefinedObj.dataformat)" class="py-2 px-0">
                  <v-autocomplete :items="fieldsShow" item-text="name" item-value="value" outlined dense hide-details :label="$t('show')"
                    v-model="userdefinedObj.showvalueas" :loading="moduleFieldsLoading" @change="userdefinedObj.sumfield = null"></v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="([1, 2].includes(userdefinedObj.dataformat)) && userdefinedObj.showvalueas === 2" class="py-2 px-0">
                  <v-autocomplete :disabled="!listOfFields.length" small-chips :items="listOfFields" :item-text="val => $t(val.label)" item-value="_id" outlined dense hide-details :label="$t('selectSumField')"
                    v-model="userdefinedObj.sumfield" :loading="moduleFieldsLoading" :rules="$_requiredValidation"></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-2 px-0">
                  {{ $t('whoCanSee') }} :
                  <v-radio-group column v-model="widgetsVisibleTo" class="py-0 mt-2">
                    <v-row class="py-0">
                      <v-col cols="12" class="pb-0">
                        <template v-for="n in widgetVisibility">
                          <v-radio :label="n.name" :value="n.id" :key="'radio'+n.id"></v-radio><v-spacer :key="'spacer'+n.id"></v-spacer>
                        </template>
                      </v-col>
                      <v-col cols="12" v-if="widgetsVisibleTo === 3">
                        <v-autocomplete label="Users" :items="userList" dense hide-details outlined item-text="name" item-value="_id" v-model="usersToVisibleWidget" multiple>
                          <template v-slot:selection="{ item, index }">
                            <!-- <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined> -->
                              <span v-if="index <= 0" style="font-size:13px;">{{ item.name }}</span>
                            <!-- </v-chip> -->
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ usersToVisibleWidget.length - 1 }} others)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="py-1 px-0">
                  {{ $t('filters') }}
                  <v-simple-table dense width="99%" class="wrapper-table mt-2">
                    <v-expansion-panels flat v-model="activeFilterPanel" multiple>
                      <v-expansion-panel v-for="(filter, filterIndex) in userdefinedObj.conditions" :key="filterIndex" class="mb-2">
                        <v-expansion-panel-header class="pa-0 flex-row-reverse" style="border: 1px solid rgba(0, 0, 0, 0.12) !important">
                          <v-row class="ma-0" style="padding-top: 1px">
                            <v-col cols="3" class="pa-0 text-center ma-auto body-2 font-weight-bold grey--text text--darken-1">{{ $t('field') }}</v-col>
                            <v-col cols="7" class="pa-0">
                              <v-select @click.stop="" outlined :items="listOfFieldsForFilter" class="mb-1 custom-select-field" :item-text="val => $t(val.label)" item-value="_id" dense hide-details v-model="filter.field"
                                :rules="$_requiredValidation" @change="findFieldType(filter.field, filterIndex); filter.inputValue = ''"></v-select>
                            </v-col>
                            <v-col cols="2" class="pa-0 text-end">
                              <v-btn color="secondary" text fab dark x-small class="mt-1 pb-0" @click="activeFilterPanel.pop(); userdefinedObj.conditions.splice(filterIndex, 1); checkRepeatedField()"><v-icon dark>mdi-minus-circle</v-icon></v-btn>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="table-expansion-content">
                          <v-simple-table dense width="99%" class="dense_table bordered--table">
                            <tbody :key="filterIndex">
                              <tr>
                                <td style="width: 28%; height: 38px" class="pl-3 caption font-weight-bold grey--text text--darken-1" id="">{{ $t('condition') }}</td>
                                <td>
                                  <v-select v-if="filter.field" :items="filter.fieldtype === 3 ? filterItemsSelect : filter.fieldtype === 4 ? filterItemsSelect : filter.fieldtype === 13 ? filterItemsNumber : ([5, 15].includes(filter.fieldtype) ? filterItemsSelect : ([6, 16].includes(filter.fieldtype) ? filterItemsDate  : filter.fieldtype === 7 ? filterItemsTime : filterItemsDefault))" class="mb-2" :item-text="val => $t(val.text)" item-value="value" dense hide-details v-model="filter.condition"
                                    :rules="$_requiredValidation"></v-select>
                                </td>
                              </tr>
                              <tr>
                                <td style="width: 28%; height: 38px" class="pl-3 caption font-weight-bold grey--text text--darken-1" id="">{{ $t('value') }}</td>
                                <td>
                                  <template  v-if="filter.fieldtype === 5 && filter.condition">
                                    <template>
                                      <v-select v-if="filter.default_value.selectType === 'default'" :items="filter.default_value.options"
                                        :item-text="filter.enableoptiontranslations ? val => filter.is_internal ? $t(`${$route.params.name}_${filter.label}_option_${val.value}`) : $t(`${filter.label}_option_${val.value}`) : val => $t(val.label)"
                                        item-value="value" v-model="filter.inputValue" dense hide-details multiple>
                                        <template #selection="{ item, index }">
                                          <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                                            <span style="font-size:13px;">{{ field.enableoptiontranslations ? $t(`${$route.params.name}_${field.value}_option_${item.value}`) : item.label }}</span>
                                          </v-chip>
                                          <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ filter.inputValue.length - 1 }} others)
                                          </span>
                                        </template>
                                      </v-select>
                                      <v-autocomplete v-if="filter.default_value.selectType === 'module'" :items="filter.default_value.options" item-text="data.name" item-value="_id" ref="filterSearchText" v-model="filter.inputValue" hide-details
                                      @keydown="preventSpecialCharacters($event)" @keyup="$event.keyCode !== 13 ? makeLiveSearch(filter.field, filterIndex) : ''" class="pt-0" multiple>
                                      <template #selection="{ item, index }">
                                        <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                                          <span style="font-size:13px;">{{ item.data.name }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                          (+{{ filter.inputValue.length - 1 }} others)
                                        </span>
                                      </template>
                                      </v-autocomplete>
                                      <v-text-field v-else-if="filter.default_value.selectType === 'custom'" dense hide-details v-model="filter.inputValue" :placeholder="$t('typeHere')"></v-text-field>
                                    </template>
                                  </template>
                                  <template  v-if="filter.fieldtype === 6 && filter.condition">
                                    <v-row v-if="!['today', 'thismonth', 'thisweek', 'withinnext', 'withinpast'].includes(filter.condition)">
                                      <v-col v-if="['created_at', 'modified_at', 'before', 'after'].includes(filter.condition || filter._id)">
                                        <template>
                                          <v-menu v-model="filter.datePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290" max-width="290" :key="filterIndex">
                                            <template #activator="{ on, attrs }">
                                              <v-text-field hide-details v-model="filter.date" :placeholder="$t('date')" @focusout="filter.datePicked = $formatter.formatDate(filter.date, userDetails.dateformat, 'YYYY-MM-DD')"  @focus="filter.datePicked = $formatter.formatDate(filter.date, userDetails.dateformat, 'YYYY-MM-DD')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                            </template>
                                            <v-date-picker no-title v-model="filter.datePicked" @input="filter.datePicker = false; filter.date = $formatter.formatDate(filter.datePicked, 'YYYY-MM-DD', userDetails.dateformat)" ></v-date-picker>
                                          </v-menu>
                                        </template>
                                      </v-col>
                                      <v-col v-if="filter.condition === 'between'">
                                        <template>
                                          <v-menu v-model="filter.startdatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290" max-width="290" :key="filterIndex">
                                            <template #activator="{ on, attrs }">
                                              <v-text-field hide-details v-model="filter.startdate" :placeholder="$t('startDate')" @focusout="filter.startDatePicked = $formatter.formatDate(filter.startdate, userDetails.dateformat, 'YYYY-MM-DD')"  @focus="filter.datePicked = $formatter.formatDate(filter.startdate, userDetails.dateformat, 'YYYY-MM-DD')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                            </template>
                                            <v-date-picker no-title v-model="filter.startDatePicked" @input="filter.startdatePicker = false; filter.startdate = $formatter.formatDate(filter.startDatePicked, 'YYYY-MM-DD', userDetails.dateformat)" ></v-date-picker>
                                          </v-menu>
                                        </template>
                                      </v-col>
                                      <v-col v-if="filter.condition === 'between'">
                                        <template>
                                          <v-menu v-model="filter.enddatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290" max-width="290">
                                            <template #activator="{ on, attrs }">
                                              <v-text-field hide-details v-model="filter.enddate" :placeholder="$t('endDate')" @focusout="filter.endDatePicked = $formatter.formatDate(filter.enddate, userDetails.dateformat, 'YYYY-MM-DD')"  @focus="filter.endDatePicked = $formatter.formatDate(filter.enddate, userDetails.dateformat, 'YYYY-MM-DD')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                            </template>
                                            <v-date-picker no-title v-model="filter.endDatePicked" @input="filter.enddatePicker = false; filter.enddate = $formatter.formatDate(filter.endDatePicked, 'YYYY-MM-DD', userDetails.dateformat)" ></v-date-picker>
                                          </v-menu>
                                        </template>
                                      </v-col>
                                    </v-row>
                                    <template v-if="filter.condition === 'withinnext' || filter.condition === 'withinpast'">
                                      <v-text-field dense hide-details :placeholder="$t('Days')" v-model="filter.within" suffix="days"></v-text-field>
                                    </template>
                                  </template>
                                  <template v-else-if="filter.fieldtype === 7 && filter.condition">
                                    <v-row>
                                      <template v-if="filter.condition === 'between'">
                                        <v-col class="py-0 pt-2" cols="6" >
                                          <v-text-field dense hide-details slot="activator" v-model="filter.start" placeholder="HH:mm">
                                            <template #append>
                                              <v-dialog v-model="filter.startTimeMenu" max-width="320">
                                                <template #activator="{ on, attrs }">
                                                  <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                                </template>
                                                <v-time-picker color="primary" v-model="filter.start" format="24hr" @click:minute="filter.startTimeMenu = false" full-width></v-time-picker>
                                              </v-dialog>
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                        <v-col class="py-0 pt-2" cols="6">
                                          <v-text-field dense hide-details slot="activator" v-model="filter.end" placeholder="HH:mm">
                                            <template #append>
                                              <v-dialog v-model="filter.endTimeMenu" max-width="320">
                                                <template #activator="{ on, attrs }">
                                                  <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                                </template>
                                                <v-time-picker color="primary" v-model="filter.end" format="24hr" @click:minute="filter.endTimeMenu = false" full-width></v-time-picker>
                                              </v-dialog>
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                      </template>
                                      <template v-else>
                                        <v-text-field dense hide-details slot="activator" v-model="filter.inputValue" placeholder="HH:mm" key="timepicker">
                                          <template #append>
                                            <v-dialog v-model="filter.timeMenu" max-width="320">
                                              <template #activator="{ on, attrs }">
                                                <v-icon size="22" class="pb-1" v-on="on" v-bind="attrs"> mdi-clock-outline </v-icon>
                                              </template>
                                              <v-time-picker color="primary" v-model="filter.inputValue" format="24hr" @click:minute="filter.timeMenu = false" full-width></v-time-picker>
                                            </v-dialog>
                                          </template>
                                        </v-text-field>
                                      </template>
                                    </v-row>
                                  </template>
                                  <template v-else-if="filter.fieldtype === 16 && filter.condition">
                                    <v-row>
                                      <v-col v-if="['created_at', 'modified_at', 'before', 'after'].includes(filter.condition)" cols="12">
                                        <datetime-picker v-model="filter.date" @input="updateDateTime(filterIndex)"></datetime-picker>
                                      </v-col>
                                      <v-col v-else-if="filter.condition === 'between'" cols="12">
                                        <v-layout row align-center class="ma-0" key="between">
                                          <v-flex xs6 class="pr-2">
                                            <datetime-picker @updateStartEndDate="updateStartEndDate(filterIndex)" v-model="filter.startdate" key="startDateTime"></datetime-picker>
                                          </v-flex>
                                          <v-flex xs6 class="pl-2">
                                            <datetime-picker @updateStartEndDate="updateStartEndDate(filterIndex)" v-model="filter.enddate" key="endDateTime" enddatetime></datetime-picker>
                                          </v-flex>
                                        </v-layout>
                                      </v-col>
                                      <v-col v-if="['withinnext', 'withinpast'].includes(filter.condition)" class="py-0" cols="12">
                                        <v-text-field dense :placeholder="$t('days')" v-model="filter.within" @keydown="preventSpecialCharacters($event)" suffix="days" class="caption pt-2" :rules="$_requiredValidation"></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <v-text-field dense hide-details v-if="filter.condition && ![5, 15, 6, 16, 7].includes(filter.fieldtype)" :placeholder="$t('typeHere')" v-model="filter.inputValue" ></v-text-field>
                                  <v-select :items="listOfUsers.length ? listOfUsers : getListOfUsers({ filterIndex })" item-text="name" item-value="_id" v-model="filter.inputValue" @change="changeHandler({ filterIndex })" dense hide-details v-if="filter.fieldtype === 15" multiple>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined>
                                          <span style="font-size:13px;">{{ item.name }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                          (+{{ filter.inputValue.length - 1 }} others)
                                        </span>
                                      </template>
                                  </v-select>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-simple-table>
                  <v-flex class="text-start">
                    <v-btn color="success" x-small fab class="mt-2 mb-0 pb-0" @click="addFilterItem" :disabled="listOfFields.length === 0"><v-icon>mdi-plus</v-icon></v-btn>
                  </v-flex>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center" style="height: 44px">
              <v-btn small color="success" :loading="loading" @click="updateUserDefinedWidget()">{{`${userdefinedObj.id ? $t('update'): $t('save')}`}}</v-btn>
              <v-btn small color="error" @click="closeUserDefinedDialog">{{$t('close')}}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      <!-- </div> -->
    </v-dialog>
    <!-- Users to visible widget confirmation dialog -->
    <v-dialog v-model="widgetPermissionDialog" persistent width="300">
      <v-card>
        <v-toolbar dark height="45" :color="systemDetails.themecolor" class="widget-toolbar">
          <v-toolbar-title class="pl-1" :style="{ color: systemDetails.textcolor, fontSize: '1.1rem' }">{{ editWidgetItem.id ? $t('updateWidget') : $t('addWidget') }}</v-toolbar-title>
          <v-btn class="mr-0" icon dark small @click="widgetPermissionDialog=false"><v-icon size="22">mdi-close-circle</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 pl-8">
          <label class="font-weight-bold v-card__title pa-0 pt-1 text-subtitle-1">{{ $t('whoCanSee') }}</label>
          <v-row no-gutters>
            <v-col cols="12" lg="12">
              <v-radio-group v-model="widgetsVisibleTo" class="custom-radio">
                <v-radio v-for="n in widgetVisibility" :key="n.id" :label="n.name" :value="n.id"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" lg="12" v-if="widgetsVisibleTo === 3" class="pb-5 pr-6">
              <v-autocomplete label="Users" :items="userList" dense hide-details outlined item-text="name" item-value="_id" v-model="usersToVisibleWidget" multiple>
                <template v-slot:selection="{ item, index }">
                  <!-- <v-chip v-if="index <= 0" label small class="mt-1 hover--class rounded-sm" :color="$vuetify.theme.dark? 'white' : systemDetails.themecolor" outlined> -->
                    <span v-if="index <= 0" style="font-size:13px;">{{ item.name }}</span>
                  <!-- </v-chip> -->
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ usersToVisibleWidget.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small color="primary" :loading="buttonLoading" @click="editWidgetItem.id ? updateWidget() : saveWidget(false, true, selectedWidget)">{{ editWidgetItem.id ? $t('update') : $t('save') }}</v-btn>
          <v-btn small color="error" @click="widgetPermissionDialog=false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--remove widget confirmation dialog-->
    <v-dialog v-model="removeConfirmationDialog" width="300" persistent>
      <v-card>
        <v-toolbar dark :color="systemDetails.themecolor" class="mb-2" style="height:40px">
          <v-toolbar-title class="pb-4" :style="{ color: systemDetails.textcolor, fontSize: '1.1rem' }">{{$t('confirmation')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-3">
          <span class="text-subtitle-2">{{ $t('removeWidgetMsg') }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small color="error" @click="removeWidget(selectedWidgetItem)">{{$t('yes')}}</v-btn>
          <v-btn small color="success" @click="closeRemoveConfirmationDialog">{{$t('no')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import FILTER_CONSTANTS from '@/constants/FilterConstants.js'
import WidgetFunctions from './mixin'
export default {
  mixins: [FILTER_CONSTANTS, WidgetFunctions],
  data () {
    return {
      listOfTotalWidgets: {
        selectedcharts: []
      },
      userDefineDialog: false,
      userdefinedObj: {
        conditions: [],
        dataformat: 1,
        showvalueas: 1
      },
      moduleFieldsLoading: false,
      listOfFields: [],
      listOfVisibleFields: [],
      listOfFieldsForFilter: [],
      allowedfeatures: [],
      hasTimerPermission: false,
      hasTimerPermissionOnly: false,
      hasTicketPermission: false,
      fieldsShow: [
        { name: 'showCount', value: 1 },
        { name: 'showSum', value: 2 }
      ],
      selectedWidgetIndex: null,
      reRender: 0,
      userDefinedWidgetList: [],
      isNewUserDefinedWidget: false,
      isWidgetShow: false,
      removeConfirmationDialog: false,
      selectedWidgetItem: {},
      loading: false,
      showDashboard: true,
      widgetPermissionDialog: false,
      widgetsVisibleTo: 1,
      usersToVisibleWidget: [],
      selectedWidget: 0,
      editWidgetItem: {},
      editWidgetIndex: 0,
      currentWidgetView: 0,
      toggleSpeedDial: false,
      activeFilterPanel: [],
      listOfGroupbyFields: [],
      buttonLoading: false,
      listOfUsers: [],
      reInit: 0,
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      }
    }
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'getUsers', 'systemDetails', 'getListOfTicketStatus']),
    showWidgetNotFound () {
      return (this.currentWidgetView === 0 && !this.allWidgetsCount) || (this.currentWidgetView === 1 && !this.myWidgetsCount) || (this.currentWidgetView === 2 && !this.otherWidgetsCount)
    },
    widgetView () {
      return [
        { label: this.$t('all'), value: 0 },
        { label: this.$t('widgetCreatedByMe'), value: 1 },
        { label: this.$t('otherUsersWidgets'), value: 2 }
      ]
    },
    widgetVisibility () {
      return [
        { id: 1, name: this.$t('onlyMe') },
        { id: 2, name: this.$t('anyone') },
        { id: 3, name: this.$t('selectedUsers') }
      ]
    },
    listOfActiveModules () {
      return this.listOfModules.filter(x => x.isactive)
    },
    userList () {
      const usersList = this.$formatter.cloneVariable(this.getUsers)
      const index = usersList.findIndex(el => el._id === this.userDetails._id)
      usersList[index].disabled = true
      return usersList
    },
    widgetTemplates () {
      const items = [{
        title: this.$t('recentDocuments'),
        icon: 'mdi-file-document-edit-outline',
        value: 2,
        disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 2 && x.user_id === this.userDetails._id)
      }, {
        title: this.$t('recentActivities'),
        icon: 'mdi-history',
        value: 3,
        disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 3 && x.user_id === this.userDetails._id)
      }, {
        title: this.$t('personalDocumentRouting'),
        icon: 'mdi-text-box-check-outline',
        value: 9,
        disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 9 && x.user_id === this.userDetails._id)
      }, {
        title: this.$t('statistics'),
        icon: 'mdi-waves',
        value: 4,
        disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 4 && x.user_id === this.userDetails._id)
      }, {
        title: this.$t('quickLinks'),
        icon: 'mdi-link',
        value: 6,
        disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 6 && x.user_id === this.userDetails._id)
      }, {
        title: this.$t('hours'),
        icon: 'mdi-store-24-hour',
        value: 7,
        disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 7 && x.user_id === this.userDetails._id)
      }, {
        title: this.$t('userDefined'),
        icon: 'mdi-walk',
        value: 5
      }]
      if (!this.userDetails.issharepointstorage) items.shift()
      if (this.hasTicketPermission) {
        items.unshift(
          { title: this.$t('tickets'), icon: 'mdi-cards-outline', value: 1, disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 1 && x.user_id === this.userDetails._id) },
          { title: this.$t('ticketsOpenOrClose'), icon: 'mdi-chart-arc', value: 8, disabled: this.listOfTotalWidgets.selectedcharts.some(x => x.id === 8 && x.user_id === this.userDetails._id) }
        )
      }
      return items
    },
    dragOptionsChips () {
      return {
        animation: 200,
        group: 'group',
        disabled: false,
        ghostClass: 'ghost',
        sort: true
      }
    },
    allWidgetsCount () {
      return (this.listOfTotalWidgets && this.listOfTotalWidgets.selectedcharts) ? this.listOfTotalWidgets.selectedcharts.filter(x => x.canShow === true).length : 0
    },
    myWidgetsCount () {
      return (this.listOfTotalWidgets && this.listOfTotalWidgets.selectedcharts) ? this.listOfTotalWidgets.selectedcharts.filter(x => x.user_id === this.userDetails._id && x.canShow === true).length : 0
    },
    otherWidgetsCount () {
      return (this.listOfTotalWidgets && this.listOfTotalWidgets.selectedcharts) ? this.listOfTotalWidgets.selectedcharts.filter(x => x.user_id !== this.userDetails._id && x.canShow === true).length : 0
    }
  },
  components: {
    draggable,
    widget: () => import('./Widget.vue'),
    'datetime-picker': () => import('@/components/DateTimePicker.vue')
  },
  mounted () {
    if (this.userDetails.allowedfeatures && this.userDetails.allowedfeatures.length > 0) {
      this.allowedfeatures = this.$formatter.cloneVariable(this.userDetails.allowedfeatures)
      if (this.allowedfeatures.includes(this.TICKETS)) this.hasTicketPermission = true
      if (this.allowedfeatures.includes(this.TIMER)) this.hasTimerPermissionOnly = true
      if (this.allowedfeatures.includes(this.TICKETS) || this.allowedfeatures.includes(this.TIMER)) this.hasTimerPermission = true
    } else this.allowedfeatures = []
    this.getDashboardLayout()
  },
  methods: {
    move (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex
      }
    },
    change (value) {
      if (value.removed) {
        // insert
        this.userdefinedObj.visiblefields.splice(this.dragged.to + this.dragged.newIndex, 0, this.userdefinedObj.visiblefields[this.dragged.from])
        // delete
        if (this.dragged.from < this.dragged.to) this.userdefinedObj.visiblefields.splice(this.dragged.from, 1) // LTR
        else this.userdefinedObj.visiblefields.splice(this.dragged.from + 1, 1) // RTL
        const nameField = this.listOfVisibleFields.find(x => x.name === 'name')
        if (nameField) {
          const index = this.userdefinedObj.visiblefields.findIndex(x => x === nameField._id)
          if (index > 0) {
            this.userdefinedObj.visiblefields.splice(index, 1)
            this.userdefinedObj.visiblefields.unshift(nameField._id)
          }
        }
      }
    },
    getListOfUsers ({ filterIndex }) {
      const listOfusers = [
        { name: this.$t('currentUser'), _id: 'currentUser' },
        ...this.getUsers
      ]

      const result = (this.userdefinedObj.conditions[filterIndex] && this.userdefinedObj.conditions[filterIndex].inputValue && this.userdefinedObj.conditions[filterIndex].inputValue.length)
        ? this.userdefinedObj.conditions[filterIndex].inputValue.includes('currentUser')
          ? listOfusers.map((item) => ({ ...item, disabled: (item._id !== 'currentUser') }))
          : listOfusers.map((item) => ({ ...item, disabled: (item._id === 'currentUser') }))
        : listOfusers

      return result
    },
    changeHandler ({ filterIndex }) {
      this.listOfUsers = this.getListOfUsers({ filterIndex })
      if (this.userdefinedObj.conditions[filterIndex] && this.userdefinedObj.conditions[filterIndex].inputValue && this.userdefinedObj.conditions[filterIndex].inputValue.length) {
        if (this.userdefinedObj.conditions[filterIndex].inputValue.includes('currentUser')) {
          this.userdefinedObj.conditions[filterIndex].inputValue = ['currentUser']
          this.userdefinedObj.conditions[filterIndex].is_current_user = true
        }
        return
      }
      if (this.userdefinedObj.conditions[filterIndex]) this.userdefinedObj.conditions[filterIndex].is_current_user = false
    },
    getListOfFieldsData () {
      if (this.userdefinedObj.module) this.getListOfFields()
      this.userdefinedObj.visiblefields = []
      this.userdefinedObj.conditions = []
    },
    getDashboardLayout () {
      this.showDashboard = true
      this.$api.execute('get', 'dashboard/get_dashboard_options').then(response => {
        const result = response.data
        if (result.length) {
          result.forEach(user => {
            if (user.selectedcharts && user.selectedcharts.length) {
              user.selectedcharts.forEach(widget => {
                widget = this.setWidgetDatas(widget, user.user_id)
              })
            }
          })
          if (result.find(x => x.user_id === this.userDetails._id)) {
            // this.myWidgets = this.$formatter.cloneVariable(result.find(x => x.user_id === this.userDetails._id))
            this.listOfTotalWidgets = this.$formatter.cloneVariable(result.find(x => x.user_id === this.userDetails._id))
          } else this.listOfTotalWidgets = this.$formatter.cloneVariable(result[0])
          this.listOfTotalWidgets.selectedcharts = this.$formatter.cloneVariable(result.map(x => x.selectedcharts).flat())
          this.listOfTotalWidgets.selectedcharts = (this.listOfTotalWidgets.selectedcharts && this.listOfTotalWidgets.selectedcharts.length) ? this.sortWidgetsByOrder(this.listOfTotalWidgets.selectedcharts, this.listOfTotalWidgets.user_id === this.userDetails._id ? this.listOfTotalWidgets.orderby : null) : []
        }
        this.getWidgetData([5, 1, 2, 3, 6, 9])
      }).finally(() => {
        this.showDashboard = false
      })
    },
    setWidgetDatas (widget, userid) {
      widget.canShow = true
      widget.isCreatedByMe = userid === this.userDetails._id
      widget.user_id = userid
      const data = this.getUsers.find(x => x._id === userid)
      widget.createdBy = (data && data.name) ? data.name : (data && data.firstname && data.lastname) ? data.firstname + data.lastname : ''
      widget = this.checkWidgetPermissions(widget)
      widget.widgetDetails = (widget.id === 1) ? { ticketsLoader: true, ticketsChartLoader: true } : { loader: true }
      return widget
    },
    sortWidgetsByOrder (selectedCharts, orderby) {
      if (selectedCharts && selectedCharts.length) {
        let charts = []
        if (orderby && orderby.length) {
          orderby.forEach((widget, index) => {
            const fieldItem = selectedCharts.find((x) => x.sortid === widget)
            if (fieldItem) charts.push(fieldItem)
            // else orderby.splice(index, 1)
          })
          charts = [...charts, ...selectedCharts.filter((x) => !orderby.includes(x.sortid))]
        } else {
          orderby = selectedCharts.map(x => x.sortid)
          charts = selectedCharts
        }
        // this.widgetsOrderBy = orderby
        return charts
      }
    },
    checkWidgetPermissions (widget) {
      if (widget.id === 1 || widget.id === 8) if (!this.hasTicketPermission) widget.canShow = false
      if (widget.id === 2) widget.canShow = this.userDetails.issharepointstorage
      if (widget.id === 5) {
        widget.conditions.forEach(x => {
          const value = JSON.parse(x.value)
          for (const i in value) { x[i] = value[i] }
          delete widget.conditions.value
        })
      }
      return widget
    },
    getListOfFields () {
      this.listOfVisibleFields = []
      this.moduleFieldsLoading = true
      const model = { moduleid: this.userdefinedObj.module, exceptfieldtypes: [9, 11, 10, 19], include_default_fields: true, isactive_alone: true }
      this.$api.execute('post', 'modulefields/filter', model)
        .then(({ data }) => {
          this.listOfFields = data
          this.listOfGroupbyFields = data.filter(x => x.name !== 'isanonymous' && ![1, 2, 7, 12, 13, 14, 16, 18].includes(x.type) && !(x.type === 5 && (JSON.parse(x.default_value).is_multiselect || JSON.parse(x.default_value).selectType === 'module' || JSON.parse(x.default_value).selectType === 'custom')) && !(x.type === 15 && (JSON.parse(x.default_value).is_multiselect)))
          this.listOfFieldsForFilter = this.listOfFields.slice()
          this.listOfFieldsForFilter = this.listOfFieldsForFilter.filter(x => x.name !== 'isanonymous')
          if (this.userdefinedObj.visiblefields && this.userdefinedObj.visiblefields.length) {
            this.listOfVisibleFields = this.$formatter.cloneVariable(this.sortFieldsByVisibleFields(this.userdefinedObj.visiblefields, data))
          } else this.listOfVisibleFields = this.$formatter.cloneVariable(data)
          this.moduleFieldsLoading = false
        }).finally(() => this.checkRepeatedField())
    },
    sortFieldsByVisibleFields (visiblefields, fields) {
      let result = []
      visiblefields.forEach((field) => {
        const fieldItem = fields.find((x) => x._id === field)
        if (fieldItem) result.push(fieldItem)
      })
      result = [...result, ...fields.filter((x) => !visiblefields.includes(x._id))]
      // update name field position
      const nameFieldIndex = result.findIndex((x) => x.name === 'name')
      const nameField = result[nameFieldIndex]
      if (nameFieldIndex <= 0) return result
      result.splice(nameFieldIndex, 1)
      result.unshift(nameField)
      return result
    },
    checkNameField () {
      const nameField = this.listOfFields.find((field) => field.name === 'name')
      if (!nameField) return
      if (this.userdefinedObj.visiblefields.length && this.userdefinedObj.visiblefields.includes(nameField._id)) {
        const index = this.userdefinedObj.visiblefields.indexOf(nameField._id)
        this.userdefinedObj.visiblefields.splice(index, 1)
        this.userdefinedObj.visiblefields.unshift(nameField._id)
      }
    },
    addFilterItem () {
      if (!this.userdefinedObj.conditions.length || this.userdefinedObj.conditions[this.userdefinedObj.conditions.length - 1].field) this.userdefinedObj.conditions.push({})
      this.activeFilterPanel.push(this.userdefinedObj.conditions.length - 1)
    },
    closeUserDefinedDialog () {
      this.userdefinedObj = { conditions: [], dataformat: 1, showvalueas: 1 }
      this.listOfFields = []
      this.listOfGroupbyFields = []
      this.$refs.userdefineDialog.reset()
      this.$refs.userdefineDialog.resetValidation()
      this.isNewUserDefinedWidget = this.userDefineDialog = false
    },
    addNewWidget (selectedWidget) {
      this.userdefinedObj = { ...this.userdefinedObj, conditions: [], dataformat: 1, showvalueas: 1 }
      this.widgetsVisibleTo = 1
      this.editWidgetItem = {}
      this.usersToVisibleWidget = [this.userDetails._id]
      if (selectedWidget === 5) { // 5 - Userdefined
        this.isNewUserDefinedWidget = this.userDefineDialog = true
      } else {
      // } else if ((selectedWidget === 1) || (selectedWidget === 8)) {
        this.widgetPermissionDialog = true
        this.selectedWidget = selectedWidget
      }
      // } else this.saveWidget(false, selectedWidget)
    },
    async editUserDefinedWidget (item, index) {
      this.widgetsVisibleTo = item.visibleto
      if (item.visibleto === 3) this.usersToVisibleWidget = item.userstovisible
      else this.usersToVisibleWidget = [this.userDetails._id]
      if (item.id === 5) {
        const model = this.$formatter.cloneVariable(item)
        this.isNewUserDefinedWidget = false
        this.userdefinedObj = model
        if (this.userdefinedObj.conditions && this.userdefinedObj.conditions.length) {
          this.userdefinedObj.conditions.forEach(condition => {
            if ([6, 16].includes(condition.fieldtype)) {
              for (const i in condition) {
                if (condition.fieldtype === 6 && ['date', 'startdate', 'enddate'].includes(i)) {
                  condition[i] = this.$formatter.formatDate(condition[i], 'DD.MM.YYYY', this.userDetails.dateformat)
                }
                // else if (['date', 'startdate', 'enddate'].includes(i)) condition[i] = this.$formatter.formatDate(condition[i], 'DD.MM.YYYY HH:mm', this.userDetails.dateformat)
              }
            }
          })
        }
        this.selectedWidgetIndex = index
        await this.getListOfFields()
        this.userDefineDialog = true
        for (let i = 0; i < this.userdefinedObj.conditions.length; i++) {
          this.activeFilterPanel.push(i)
        }
      } else {
        this.editWidgetItem = item
        this.editWidgetIndex = index
        this.widgetPermissionDialog = true
      }
    },
    updateUserDefinedWidget () {
      if (this.$refs.userdefineDialog.validate()) {
        const model = this.$formatter.cloneVariable(this.userdefinedObj)
        model.visibleto = this.widgetsVisibleTo
        model.userstovisible = model.visibleto === 3 ? this.usersToVisibleWidget : []
        model.index = this.selectedWidgetIndex
        model.type = 2
        model.id = 5
        if (!model.groupby) model.groupby = null
        model.conditions.forEach(condition => {
          condition.value = {}
          for (const i in condition) {
            if (!['field', 'condition', 'fieldtype', 'value'].includes(i)) condition.value[i] = condition[i]
            if (condition.fieldtype === 6 && ['date', 'startdate', 'enddate'].includes(i)) condition.value[i] = this.$formatter.formatDate(condition[i], this.userDetails.dateformat, 'DD.MM.YYYY')
          }
          condition.value = JSON.stringify(condition.value)
        })
        this.saveWidget(false, this.isNewUserDefinedWidget, 5, model)
        this.closeUserDefinedDialog()
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    updateWidget () {
      this.buttonLoading = true
      const item = this.listOfTotalWidgets.selectedcharts[this.editWidgetIndex]
      item.visibleto = this.widgetsVisibleTo
      item.userstovisible = item.visibleto === 3 ? this.usersToVisibleWidget : []
      this.widgetPermissionDialog = false
      const widgets = this.$formatter.cloneVariable(this.listOfTotalWidgets)
      widgets.selectedcharts.splice(this.editWidgetIndex, 1, item)
      widgets.selectedcharts = widgets.selectedcharts.filter(x => x.user_id === this.userDetails._id)
      this.$api.execute('post', 'dashboard/save_my_dashboard_options', widgets).then(() => {
        this.buttonLoading = false
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'saveSuccess' })
      })
    },
    saveWidget (isDragAndDrop, isNewWidget, selectedWidgetIndex, userDefinedModel) {
      this.widgetPermissionDialog = false
      this.loading = true
      const widgets = this.$formatter.cloneVariable(this.listOfTotalWidgets)
      widgets.user_id = this.userDetails._id
      delete widgets._id
      if (!isDragAndDrop) {
        if (selectedWidgetIndex === 5) {
          if (this.isNewUserDefinedWidget) {
            widgets.selectedcharts.push({
              ...userDefinedModel,
              user_id: this.userDetails._id,
              index: widgets.selectedcharts.length ? widgets.selectedcharts.length + 2 : 0,
              visibleto: this.widgetsVisibleTo,
              userstovisible: this.widgetsVisibleTo === 3 ? this.usersToVisibleWidget : []
            })
          } else widgets.selectedcharts[this.selectedWidgetIndex] = userDefinedModel
        } else {
          widgets.selectedcharts.push({
            id: selectedWidgetIndex,
            user_id: this.userDetails._id,
            visibleto: this.widgetsVisibleTo,
            userstovisible: this.widgetsVisibleTo === 3 ? this.usersToVisibleWidget : [],
            index: widgets.selectedcharts.length ? widgets.selectedcharts.length + 2 : 0,
            ticketChartOption: {}
          })
        }
      }
      widgets.selectedcharts = widgets.selectedcharts ? widgets.selectedcharts.filter(x => x.user_id === this.userDetails._id) : []
      this.$api.execute('post', 'dashboard/save_my_dashboard_options', widgets).then((response) => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'saveSuccess' })
        if (!isDragAndDrop) {
          let newWidget = (isNewWidget) ? response.data.selectedcharts[response.data.selectedcharts.length - 1] : response.data.selectedcharts.find(x => x.sortid === userDefinedModel.sortid)
          if (newWidget) {
            newWidget = this.setWidgetDatas(newWidget, this.userDetails._id)
            if (isNewWidget) {
              newWidget.databaseindex = (this.listOfTotalWidgets.selectedcharts && this.listOfTotalWidgets.selectedcharts.length) ? this.listOfTotalWidgets.selectedcharts.length : 0
              this.listOfTotalWidgets.selectedcharts.push(newWidget)
            } else {
              const index = this.listOfTotalWidgets.selectedcharts.findIndex(x => x.sortid === userDefinedModel.sortid)
              this.listOfTotalWidgets.selectedcharts.splice(index, 1, newWidget)
            }
            this.getWidgetData([newWidget.id])
          }
        }
        if (selectedWidgetIndex === 5) {
          this.isNewUserDefinedWidget = this.userDefineDialog = false
          this.userdefinedObj = { conditions: [], dataformat: 1, showvalueas: 1 }
          this.$refs.userdefineDialog.reset()
        }
        this.loading = false
      })
    },
    removeWidgetConfirmation (index, id) {
      this.selectedWidgetItem = { index, id }
      this.removeConfirmationDialog = true
    },
    closeRemoveConfirmationDialog () {
      this.selectedWidgetItem = {}
      this.removeConfirmationDialog = false
    },
    removeWidget ({ index, id }) {
      this.listOfTotalWidgets.selectedcharts.splice(index, 1)
      this.closeRemoveConfirmationDialog()
      const widgets = this.$formatter.cloneVariable(this.listOfTotalWidgets)
      widgets.orderby = widgets.selectedcharts.map(x => x.sortid)
      widgets.selectedcharts = widgets.selectedcharts.filter(x => x.user_id === this.userDetails._id)
      this.$api.execute('post', 'dashboard/save_my_dashboard_options', widgets)
    },
    findFieldType (id, index) {
      this.checkRepeatedField()
      if (![this.CREATED_BY, this.CREATED_AT, this.MODIFIED_BY, this.MODIFIED_AT].includes(id)) {
        if (this.userdefinedObj.conditions[index].filter) this.userdefinedObj.conditions[index].filter = null
        const result = this.listOfFields.find(x => x._id === id)
        this.userdefinedObj.conditions[index].fieldtype = result.type
        if (result.type === 5) {
          this.userdefinedObj.conditions[index].enableoptiontranslations = result.enableoptiontranslations
          this.userdefinedObj.conditions[index].label = result.label
        }
        if (result && JSON.parse(result.default_value)) this.userdefinedObj.conditions[index].default_value = JSON.parse(result.default_value)
        this.widgetDetails.conditions[index].is_internal = result.is_internal
      } else {
        if ([this.CREATED_BY, this.MODIFIED_BY].includes(id)) this.userdefinedObj.conditions[index].fieldtype = 15
        else this.userdefinedObj.conditions[index].fieldtype = 16
      }
    },
    checkRepeatedField () {
      if (this.userdefinedObj && this.userdefinedObj.conditions) {
        const filterFields = this.userdefinedObj.conditions.map(x => x.field)
        this.listOfFieldsForFilter = this.listOfFieldsForFilter.map(x => {
          if (filterFields.includes(x._id)) x.disabled = true
          else x.disabled = false
          return x
        })
      }
      this.listOfUsers = []
    },
    makeLiveSearch (field, index) {
      const currentSelectedModule = this.listOfFields.find(x => x._id === field)
      const searchTerm = this.$refs.filterSearchText[0].lazySearch
      if (currentSelectedModule.default_value) {
        const moduleName = JSON.parse(currentSelectedModule.default_value).selectedModule
        this.$api.execute('get', `moduledata/${moduleName}/search?searchterm=${searchTerm}`).then(response => {
          if (moduleName === 'Account' && currentSelectedModule.name === 'supplier') {
            const supplierTypeItems = response.data.filter(x => x.data && x.data.type === 'supplier')
            this.userdefinedObj.conditions[index].default_value.options = supplierTypeItems
          } else this.userdefinedObj.conditions[index].default_value.options = response.data
        })
      }
    },
    updateStartEndDate (index) {
      this.$set(this.userdefinedObj.conditions[index], 'inputStartDate', this.$formatter.formatDate(this.userdefinedObj.conditions[index].startdate, 'YYYY-MM-DDTHH:mm', `${this.userDetails.dateformat} HH:mm`))
      this.$set(this.userdefinedObj.conditions[index], 'inputEndDate', this.$formatter.formatDate(this.userdefinedObj.conditions[index].enddate, 'YYYY-MM-DDTHH:mm', `${this.userDetails.dateformat} HH:mm`))
      this.$set(this.userdefinedObj.conditions[index], 'startdate', this.$formatter.formatDate(this.userdefinedObj.conditions[index].startdate, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DDTHH:mm:ss'))
      this.$set(this.userdefinedObj.conditions[index], 'enddate', this.$formatter.formatDate(this.userdefinedObj.conditions[index].enddate, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DDTHH:mm:ss'))
    },
    updateDateTime (index) {
      this.$set(this.userdefinedObj.conditions[index], 'inputDate', this.$formatter.formatDate(this.userdefinedObj.conditions[index].date, 'YYYY-MM-DDTHH:mm', `${this.userDetails.dateformat} HH:mm`))
      this.$set(this.userdefinedObj.conditions[index], 'date', this.$formatter.formatDate(this.userdefinedObj.conditions[index].date, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DDTHH:mm:ss'))
    }
  }
}
</script>
<style>
.dashboard .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 33px !important;
}
.dashboard .v-input {
  font-size: 13px !important;
}
.dashboard .v-input__icon {
  height: 17px !important;
}
.statistics .row {
  margin-right: 0px !important;margin-left: 0px !important;
}
.listitemwrapperDashbord .v-list-item {
  display: flex !important;
  flex-direction: column !important;
  cursor: pointer;
  height: 25px;
  margin-bottom: 12px;
}
.listitemwrapperDashbord .v-list-item__icon {
  align-self: left !important;
  margin-left: -10px;
}
.listitemwrapperDashbord .v-list-item {
  min-height: 0px !important;
  padding: 0px;
}
header.widget-toolbar-custom-height, header.widget-toolbar-custom-height > div.v-toolbar__content  {
  height: 45px !important;
}
.panel-content .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  border: none !important;
}
.widget-custom-speed-dial {
  bottom: 65px !important;
  right: 1vw !important;
}
.widget-custom-speed-dial div.v-speed-dial__list {
  align-items: flex-end !important;
}
.widget-custom-speed-dial .v-speed-dial__list{
  padding: 0px !important;
}
.widget-custom-speed-dial div.v-speed-dial__list .theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: black !important;
}
.toolbar .v-toolbar__content, .v-toolbar__extension {
  justify-content: end;
}
  .widget-select fieldset{
    color: white !important;
  }
.widget-select .v-icon.v-icon {
  color: white !important;
}
.widget-select .v-select__selection {
  color: white;
  font-weight: bold;
}
.table-expansion-content .v-expansion-panel-content__wrap{
  padding: 0px !important;
}
.table-expansion-content .bordered--table table thead th, .bordered--table table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.dense_table tbody{
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.wrapper-table > .v-data-table__wrapper > table{
  width: 99% !important;
}
.wrapper-table fieldset{
  top: 3px !important;
  bottom: 5px !important;
}
.wrapper-table .v-expansion-panel-header{
  min-height: 40px !important;
  height: 40px !important;
}
.wrapper-table .v-input__append-inner {
  margin-top: 10px !important;
}
.wrapper-table .v-expansion-panel-header__icon{
  padding-left: 8px;
}
.custom-radio.v-input--selection-controls{
  margin-top: 0px;
}
.widget-toolbar .v-toolbar__content{
  justify-content: space-between;
}
.visible-fields .v-select__selections {
  margin-top: 2px;
}
</style>
